import NextScript from 'next/script';
import type { ElementType } from 'react';

import { customConsent, locale } from '@hubcms/brand';

type DidomiSDKProps = {
  script?: ElementType;
  isNoticeEnabled?: boolean;
};

const [langString] = locale.split('-');
const hasCustomConsent = Boolean(customConsent);

export function DidomiSDK({ script: Script = NextScript, isNoticeEnabled = !hasCustomConsent }: DidomiSDKProps) {
  const isNextScript = Script === NextScript;
  const strategy = isNextScript ? 'beforeInteractive' : undefined;

  return (
    <>
      <Script
        id="didomi-mh-script"
        strategy={strategy}
        dangerouslySetInnerHTML={{
          __html: /* js */ `window.gdprAppliesGlobally=true;(function(){function a(e){if(!window.frames[e]){if(document.body&&document.body.firstChild){var t=document.body;var n=document.createElement("iframe");n.style.display="none";n.name=e;n.title=e;t.insertBefore(n,t.firstChild)}
else{setTimeout(function(){a(e)},5)}}}function e(n,r,o,c,s){function e(e,t,n,a){if(typeof n!=="function"){return}if(!window[r]){window[r]=[]}var i=false;if(s){i=s(e,t,n)}if(!i){window[r].push({command:e,parameter:t,callback:n,version:a})}}e.stub=true;function t(a){if(!window[n]||window[n].stub!==true){return}if(!a.data){return};var i=typeof a.data==="string";var e;try{e=i?JSON.parse(a.data):a.data}catch(t){return}if(e[o]){var r=e[o];window[n](r.command,r.parameter,function(e,t){var n={};n[c]={returnValue:e,success:t,callId:r.callId};a.source.postMessage(i?JSON.stringify(n):n,"*")},r.version)}};if(typeof window[n]!=="function"){window[n]=e;if(window.addEventListener){window.addEventListener("message",t,false)}else{window.attachEvent("onmessage",t)}}}e("__tcfapi","__tcfapiBuffer","__tcfapiCall","__tcfapiReturn");a("__tcfapiLocator");(function(e){var t=document.createElement("script");t.id="spcloader";t.type="text/javascript";t.async=true;t.src="https://sdk.privacy-center.org/"+e+"/loader.js?target="+document.location.hostname;t.charset="utf-8";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n)})("11ef8ac9-6270-4d5e-8b99-8d6a5bd60059")})();`,
        }}
      />
      {/* html content will be injected by cloudflare worker if needed */}
      <Script
        id="didomi-mh-config"
        strategy="beforeInteractive"
        dangerouslySetInnerHTML={{
          __html: '',
        }}
      />
      <Script
        id="didomi-mh-script-lang"
        strategy={strategy}
        dangerouslySetInnerHTML={{
          __html: /* js */ `window.didomiConfig={...window.didomiConfig,languages:{enabled:['${langString}'],default:'${langString}'},user:{...(window.didomiConfig&&window.didomiConfig.user),externalConsent:{enabled:true}},notice:{enable: ${isNoticeEnabled}}};`,
        }}
      />
      <Script
        id="didomi-mh-script-events"
        strategy={strategy}
        dangerouslySetInnerHTML={{
          __html: /* js */ `window.didomiEventListeners=window.didomiEventListeners||[];window.didomiEventListeners.push({event:'consent.changed',listener:function(e){document.dispatchEvent(new CustomEvent('Didomi.consent.changed',{detail:e}))}})`,
        }}
      />
    </>
  );
}
