import type { CuePublicationName, MHEnv } from './type-server-config';

export function isLocalEnv(environment: string) {
  return environment === 'LOCAL';
}

export function isProdEnv(environment: string) {
  return environment === 'PROD';
}

export function isValidEnv(candidate: string): candidate is MHEnv {
  return ['DEVTEST', 'TEST', 'STAGING', 'PROD', 'LOCAL'].includes(candidate);
}

export function isValidCuePublicationName(candidate: string): candidate is CuePublicationName {
  return [
    'aachener-zeitung-online',
    'contacto-online',
    'mhli-dl-online',
    'de-gooi-en-eemlander-online',
    'de-telegraaf-online',
    'mhbe-gva-online',
    'mhbe-hbvl-online',
    'mhbe-ds-online',
    'haarlems-dagblad-online',
    'leidsch-dagblad-online',
    'luxembourg-times-online',
    'noordhollands-dagblad-online',
    'hub-gazette-online',
    'virgule-online',
    'luxemburger-wort-online',
    'ijmuider-courant-online',
    'mhnl-tel-online',
  ].includes(candidate);
}
